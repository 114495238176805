import React from "react"
import '../../fonts.css'

const Types = {
    REGULAR: 'Regular',
    MEDIUM: 'Medium',
    BOLD: 'Bold',
}

class Text extends React.Component {
    render() {
        if (this.props.type === Types.MEDIUM) {
            return (
                <div style={{
                    fontFamily: this.props.type,
                    color: 'white',
                    cursor: 'default',
                    ...this.props.style
                }}
                    className={this.props.className}>
                    <b>{this.props.children}</b>
                </div>
            )
        } else {
            return (
                <div style={{
                    fontFamily: this.props.type,
                    color: 'white',
                    cursor: 'default',
                    ...this.props.style
                }}
                    className={this.props.className}>
                    {this.props.children}
                </div>
            )
        }
    }
}

export { Types, Text }