import '../../fonts.css'
import React from "react"
import { TelephoneTemplate } from "../templates/TelephoneTemplate"


class MobilePage extends React.Component {
    render() {
        return(
            <TelephoneTemplate/>
        )
    }
}

export { MobilePage }