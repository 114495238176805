import '../../fonts.css'
import React from 'react';
import { LargeTemplate } from '../templates/ComputerTemplate';

class MainPage extends React.Component {
    render() {
        return (
           <LargeTemplate/> 
        )
    }
}

export { MainPage }
